import axios from 'axios'
import _ from 'lodash'

const state = {
  clienti: [],
  clienteCorrente: undefined,
  pagination: {
    page: 1,
    pageCount: 0
  },
  options: {}
}

// getters
const getters = {
  clienti: state => {
    return state.clienti
  },
  clienteCorrente: state => {
    return state.clienteCorrente
  },
  pagination: state => {
    return state.pagination
  },
}

// actions
const actions = {
  refresh({
    commit,
    state
  }) {
    let sort = _.map(state.options.sortBy, (fieldName, fieldIndex) => (`${fieldName}:${state.options.sortDesc[fieldIndex] ? 'desc' : 'asc'}`))
    axios.get('/clienti', {
        params: {
          sort: sort,
          limit: state.options.itemsPerPage,
          page: state.options.page,
          q: state.options.q
        }
      })
      .then((response) => {
        commit('set', response.data)
      })
  },
  get({
    commit
  }, id) {
    commit('setClienteCorrente', undefined)
    axios.get(`/clienti/${id}`)
      .then((response) => {
        commit('setClienteCorrente', response.data)
      })
  },
  create({
    dispatch
  }, cliente) {
    axios.post('/clienti', cliente)
      .then(() => {
        dispatch('refresh')
      })
  },
  update({
    dispatch
  }, cliente) {
    axios.put(`/clienti/${cliente.id}`, cliente)
      .then(() => {
        dispatch('refresh')
      })
  },
  delete({
    dispatch
  }, id) {
    axios.delete(`/clienti/${id}`)
      .then(() => {
        dispatch('refresh')
      })
  },
  changeOptions({
    dispatch,
    commit
  }, options) {
    commit('setOptions', options)
    dispatch('refresh')
  }
}

// mutations
const mutations = {
  set(state, clienti) {
    state.clienti = clienti.data
    state.pagination = clienti.pagination
  },
  setClienteCorrente(state, clienteCorrente) {
    state.clienteCorrente = clienteCorrente
  },
  setOptions(state, options) {
    state.options = options
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}