import axios from 'axios'
import _ from 'lodash'

const state = {
  ordini: [],
  ordineCorrente: undefined,
  pagination: {
    page: 1,
    pageCount: 0
  },
  options: {}
}

// getters
const getters = {
  ordini: state => {
    return state.ordini
  },
  ordineCorrente: state => {
    return state.ordineCorrente
  },
  pagination: state => {
    return state.pagination
  },
}

// actions
const actions = {
  refresh({
    commit,
    state
  }) {
    let sort = _.map(state.options.sortBy, (fieldName, fieldIndex) => (`${fieldName}:${state.options.sortDesc[fieldIndex] ? 'desc' : 'asc'}`))
    axios.get('/ordini', {
        params: {
          sort: sort,
          limit: state.options.itemsPerPage,
          page: state.options.page,
          q: state.options.q
        }
      })
      .then((response) => {
        commit('set', response.data)
      })
  },
  get({
    commit
  }, id) {
    commit('setOrdineCorrente', undefined)
    axios.get(`/ordini/${id}`)
      .then((response) => {
        commit('setOrdineCorrente', response.data)
      })
  },
  create({
    dispatch,
    commit
  }, ordine) {
    axios.post('/ordini', ordine)
      .then((response) => {
        commit('setOrdineCorrente', response.data)
        dispatch('refresh')
      })
  },
  update({
    dispatch
  }, ordine) {
    axios.put(`/ordini/${ordine.id}`, ordine)
      .then(() => {
        dispatch('refresh')
      })
  },
  delete({
    dispatch
  }, id) {
    axios.delete(`/ordini/${id}`)
      .then(() => {
        dispatch('refresh')
      })
  },
  changeOptions({
    dispatch,
    commit
  }, options) {
    commit('setOptions', options)
    dispatch('refresh')
  },
}

// mutations
const mutations = {
  set(state, ordini) {
    state.ordini = ordini.data
    state.pagination = ordini.pagination
  },
  setOrdineCorrente(state, ordineCorrente) {
    state.ordineCorrente = ordineCorrente
  },
  setOptions(state, options) {
    state.options = options
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}