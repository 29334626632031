<template>
  <v-row justify="center" class="fill-height mt-5">
    <v-col xs="9" md="4">
      <v-card>
        <v-card-title class="headline primary text-xs-center white--text">
          <h4 style="vertical-align: middle">
            <v-icon color="white">fas fa-key</v-icon>
            <span class="pl-3">Accedi</span>
          </h4>
        </v-card-title>
        <v-card-text class="text-xs-center">
          <v-form @submit.prevent="login()">
            <v-text-field
              prepend-icon="fas fa-user"
              name="Email"
              label="Email"
              color="grey darken-3"
              v-model="email"
            ></v-text-field>
            <v-text-field
              prepend-icon="fa fa-lock"
              name="Password"
              label="Password"
              type="password"
              color="grey darken-3"
              v-model="password"
            ></v-text-field>
            <v-card-actions class="text-xs-center">
              <v-btn class="primary" dark type="submit">Login</v-btn>
            </v-card-actions>
          </v-form>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {
      email: '',
      password: ''
    }
  },
  methods: {
    login () {
      this.$store.dispatch('auth/login', {
        email: this.email,
        password: this.password
      })
    }
  }
}
</script>

<style scoped></style>
