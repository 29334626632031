import axios from 'axios'
import router from '../../router'

const getInitials = (name) => {
  const initials = name.match(/\b\w/g) || [];
  return ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
}

const state = {
  idToken: null,
  userId: null,
  user: null
}

// getters
const getters = {
  user (state) {
    return state.user
  },
  isAuthenticated (state) {
    return state.idToken !== null
  }
}

// actions
const actions = {
  setLogoutTimer ({
    commit
  }, expirationTime) {
    setTimeout(() => {
      commit('clearAuthData')
    }, expirationTime * 1000)
  },
  signup ({
    commit
  }, authData) {
    axios
      .post('/auth/local/register', {
        email: authData.email,
        password: authData.password
      })
      .then(res => {
        console.log(res)
        commit('authUser', {
          token: res.data.jwt,
          userId: res.data.user.id
        })
        /* const now = new Date();
        const expirationDate = new Date(
          now.getTime() + 15000 * 1000
        ); */
        localStorage.setItem('token', res.data.token)
        localStorage.setItem('userId', res.data._id)
        // localStorage.setItem("expirationDate", expirationDate);
        // dispatch("storeUser", authData);
        // dispatch("setLogoutTimer", res.data.expiresIn);
      })
      .catch(error => console.log(error))
  },
  login ({
    commit,
    dispatch
  }, authData) {
    axios
      .post('/auth/local', {
        email: authData.email,
        password: authData.password
      })
      .then(res => {
        localStorage.setItem('token', res.data.token)
        localStorage.setItem('userId', res.data._id)
        commit('authUser', {
          token: res.data.token,
          userId: res.data._id
        })
        axios
          .get('/users/local/me').then(res => {
            commit('setUser', res.data)
          }).catch(error => {
            // Non sono riuscito ad autenticare l'utente, segnalo errore e sloggo
            dispatch('logout')
            console.log(error)
          })
      })
      .catch(error => console.log(error))
  },
  tryAutoLogin ({
    commit,
    dispatch
  }) {
    const token = localStorage.getItem('token')
    if (!token) {
      return
    }
    const userId = localStorage.getItem('userId')
    commit('authUser', {
      token: token,
      userId: userId
    })
    axios
      .get('/users/local/me').then(res => {
        commit('setUser', res.data)
      }).catch(error => {
        // Non sono riuscito ad autenticare l'utente, segnalo errore e sloggo
        dispatch('logout')
        console.log(error)
      })
  },
  logout ({
    commit
  }) {
    commit('clearAuthData')
    localStorage.removeItem('token')
    localStorage.removeItem('userId')
    router.replace('/login')
  }
}

// mutations
const mutations = {
  authUser (state, userData) {
    state.idToken = userData.token
    state.userId = userData.userId
    axios.defaults.headers.common['Authorization'] = 'Bearer ' + userData.token
    if (router.currentRoute.query.redirect) {
      router.replace(router.currentRoute.query.redirect)
    } else if (router.currentRoute.name === 'login') {
      router.replace('/')
    }
  },
  clearAuthData (state) {
    state.idToken = null
    state.userId = null
    state.user = null
  },
  setUser (state, user) {
    state.user = user
    state.user.initials = getInitials(user.name)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
