import axios from 'axios'
import _ from 'lodash'

const state = {
    users: [],
    currentUser: undefined,
    pagination: {
        page: 1,
        pageCount: 0
    },
    options: {}
}

// getters
const getters = {
    users: state => {
        return state.users
    },
    currentUser: state => {
        return state.currentUser
    },
    pagination: state => {
        return state.pagination
    },
}

// actions
const actions = {
    refresh({
        commit,
        state
    }) {
        let sort = _.map(state.options.sortBy, (fieldName, fieldIndex) => (`${fieldName}:${state.options.sortDesc[fieldIndex] ? 'desc' : 'asc'}`))
        axios.get('/users', {
                params: {
                    sort: sort,
                    limit: state.options.itemsPerPage,
                    page: state.options.page,
                    q: state.options.q
                }
            })
            .then((response) => {
                commit('set', response.data)
            })
    },
    get({
        commit
    }, id) {
        commit('setCurrentUser', undefined)
        axios.get(`/users/${id}`)
            .then((response) => {
                commit('setCurrentUser', response.data)
            })
    },
    create({
        dispatch
    }, user) {
        axios.post('/users', user)
            .then(() => {
                dispatch('refresh')
            })
    },
    update({
        dispatch
    }, user) {
        axios.put(`/users/${user.id}`, user)
            .then(() => {
                dispatch('refresh')
            })
    },
    delete({
        dispatch
    }, id) {
        axios.delete(`/users/${id}`)
            .then(() => {
                dispatch('refresh')
            })
    },
    changeOptions({
        dispatch,
        commit
    }, options) {
        commit('setOptions', options)
        dispatch('refresh')
    }
}

// mutations
const mutations = {
    set(state, users) {
        state.users = users.data
        state.pagination = users.pagination
    },
    setCurrentUser(state, currentUser) {
        state.currentUser = currentUser
    },
    setOptions(state, options) {
        state.options = options
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}