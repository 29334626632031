import Vue from 'vue'
import Router from 'vue-router'
import Login from './components/auth/Login.vue'
import PrintBarcodes from './components/PrintBarcodes.vue'
import Ordini from './components/Ordini.vue'
import Magazzino from './components/Magazzino.vue'

Vue.use(Router)

export default new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [{
            path: '/login',
            name: 'login',
            component: Login
        }, {
            path: '/',
            name: 'etichette',
            component: PrintBarcodes,
            meta: {
                requiresAuth: true
            }
        }, {
            path: '/ordini',
            name: 'ordini',
            component: Ordini,
            meta: {
                requiresAuth: true
            }
        }, {
            path: '/magazzino',
            name: 'magazzino',
            component: Magazzino,
            meta: {
                requiresAuth: true
            }
        }
    ]
})
