<template>
  <v-app>
    <v-app-bar app color="primary" dark clipped-left clipped-right>
      <div class="d-flex align-center">
        <v-app-bar-nav-icon v-if="user" @click="drawer = !drawer"></v-app-bar-nav-icon>
        <!-- <v-img
          alt="Vuetify Logo"
          class="shrink mr-2"
          contain
          src="/assets/img/logo.png"
          transition="scale-transition"
          width="120"
        /> -->
        <h2><span>PROTO</span><span class="font-weight-light">TYPE</span></h2>
      </div>
      <v-spacer></v-spacer>
      <div v-if="isAuthenticated">
        <v-avatar size="36px" v-if="user">
          <span class="white--text headline">{{user.initials}}</span>
        </v-avatar>
      </div>
      <v-menu offset-y dark v-if="isAuthenticated">
        <template v-slot:activator="{ on }">
          <v-btn dark icon v-on="on">
            <v-icon>fas fa-ellipsis-v</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item @click="logout">
            <v-list-item-title>Logout</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-navigation-drawer
      v-if="user"
      v-model="drawer"
      clipped
      app
    >
      <v-sheet
        color="grey lighten-4"
        class="pa-4"
      >
        <v-avatar
          class="mb-4"
          color="grey darken-1"
          size="64"
        >
          <span class="white--text headline">{{user.initials}}</span>
        </v-avatar>

        <div>{{user.name}}</div>
      </v-sheet>

      <v-divider></v-divider>

      <v-list>
        <v-list-item
          v-for="[icon, text, to] in links"
          :key="icon"
          :to="to"
        >
          <v-list-item-icon>
            <v-icon>{{ icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ text }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <v-main style="background-color: rgb(232, 232, 232);">
      <v-container class="fill-height">
        <v-row>
          <router-view></router-view>
        </v-row>
      </v-container>
      <app-dialog></app-dialog>
    </v-main>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex'
import AppDialog from '@/components/AppDialog.vue'

export default {
  name: "App",

  components: {
    AppDialog,
  },
  
  computed: {
    ...mapGetters({
      user: 'auth/user',
      isAuthenticated: 'auth/isAuthenticated'
    })
  },

  data: () => ({
    drawer: false,
    links: [
        ['fas fa-print', 'Etichette', '/'],
        ['fas fa-boxes', 'Ordini', '/ordini'],
        ['fas fa-archive', 'Magazzino', '/magazzino'],
        ['far fa-address-book', 'Clienti', ''],
        ['fas fa-users', 'Utenti', ''],
      ],
  }),

  methods: {
    logout: function () {
      this.$store.dispatch('auth/logout')
    }
  }
};
</script>

<style>
.fa.v-icon, .fas.v-icon, .far.v-icon {font-size: 20px;}
.v-pagination__navigation .fas.v-icon {font-size: 14px;}
</style>