const state = {
  dialog: {
    title: '',
    message: '',
    type: '',
    visible: false,
    actions: []
  }
}

// getters
const getters = {
  dialog: state => {
    return state.dialog
  }
}

// actions
const actions = {
  show ({ commit }, dialog) {
    commit('set', dialog)
    commit('visible', true)
  },
  hide ({ commit }) {
    commit('visible', false)
  }
}

// mutations
const mutations = {
  set (state, dialog) {
    state.dialog.title = dialog.title
    state.dialog.message = dialog.message
    state.dialog.type = dialog.type
    state.dialog.actions = dialog.actions
  },
  visible (state, visible) {
    state.dialog.visible = visible
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
