import Vue from 'vue'
import './plugins/nprogress'
import './filters'
import App from './App.vue'
import router from './router'
import store from './store'

import vuetify from './plugins/vuetify';
import axios from 'axios';

axios.defaults.baseURL = process.env.VUE_APP_API_URL;

Vue.config.productionTip = false

store.dispatch('auth/tryAutoLogin')

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.
    if (!store.getters['auth/isAuthenticated']) {
      next({
        path: '/login',
        query: {
          redirect: to.fullPath
        }
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app')
