<template>
  <v-container>
    <v-row justify="end">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
    </v-row>
    <v-row align="center" justify="center">
      <h2>Etichette</h2>
    </v-row>
    <v-row align="end">
      <v-col cols="12" md="6">
        <v-text-field
          label="Nome lavorazione"
          v-model="nome"
          hide-details="auto"
          @keyup="queueUpdateLavorazione"
        ></v-text-field>
      </v-col>
      <v-col cols="6" md="1">
        <v-btn
          color="primary"
          text
          @click="updateLavorazione"
        >
          <v-icon class="mr-2">far fa-save</v-icon> Salva
        </v-btn>
      </v-col>
      <v-col cols="6" md="1">
        <form ref="printForm" :action="`${apiUrl}/print`" method="post" target="_blank" @submit.prevent="print">
          <input type="hidden" name="html" id="html" ref="printHtml">
          <input type="hidden" name="cellHeight" id="cellHeight" value="35.56mm">
          <input type="hidden" name="rows" id="rows" value="8">
          <input type="hidden" name="columns" id="columns" value="3">
          <input type="hidden" name="landscape" id="landscape" value="false">
          <input type="hidden" name="marginTop" id="marginTop" value="4mm">
          <input type="hidden" name="marginBottom" id="marginBottom" value="4mm">
          <input type="hidden" name="marginRight" id="marginRight" value="0">
          <input type="hidden" name="marginLeft" id="marginLeft" value="0">
          <v-btn color="primary" class="ml-3" type="submit" text><v-icon class="mr-2">fas fa-print</v-icon> Stampa</v-btn>
        </form>
      </v-col>
    </v-row>
    <v-card class="mt-3 mb-3" v-for="(label, i) in labels" :key="i">
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
              label="Codice"
              v-model="label.code"
              hide-details="auto"
              append-outer-icon="fas fa-camera"
              @click:append-outer="showCameraDialog(label)"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col sm="6">
            <v-text-field
              v-model.number="label.quantity"
              type="number"
              label="Quantità"
              class="centered-input"
              append-outer-icon="fa-plus"
              @click:append-outer="label.quantity+=1"
              prepend-icon="fa-minus"
              @click:prepend="label.quantity-=1"
            >
            </v-text-field>
          </v-col>
          <v-col sm="6">
            <v-autocomplete
              :items="formats"
              v-model="label.format"
              label="Formato"
            ></v-autocomplete>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="error"
          text
          @click="showDeleteDialog(label)"
        >
          Elimina
        </v-btn>
    </v-card-actions>
    </v-card>
    <v-row class="mt-5 mb-5" align="center" justify="center">
      <v-btn @click="addLabel">Aggiungi etichetta</v-btn>
    </v-row>
    <v-row class="mt-7 mb-3" align="center" justify="center">
      <h2>Anteprima</h2>
    </v-row>
    <v-row class="mt-3 mb-3" align="center" justify="center">
      <div id="barcodes" ref="barcodes">
        <div v-for="(labels, i) in labelsChunks" :key="i" class="page">
          <barcode
            v-for="(label, k) in labels"
            :key="k"
            :value="label.code"
            :format="label.format"
            font-options="bold"
            :height="80"
            :font-size="26"
            :margin-top="0"
            :margin-bottom="0"
            :text-margin="0"
          >
            <div class="pa-3 no-print">
              <v-alert
                border="left"
                color="red"
                dense
                icon="fas fa-exclamation-triangle"
                outlined
                text
                type="error"
              >
                Il <strong>codice non e' valido</strong> per il tipo di formato scelto! Controlla il <em>numero</em> o il <em>tipo</em> dei caratteri inseriti.
              </v-alert>
            </div>
          </barcode>
        </div>
      </div>
    </v-row>
    <v-navigation-drawer
      width="330"
      clipped
      v-model="drawer"
      right
      app
    >
      <v-sheet
        color="grey lighten-4"
        class="pa-4"
      >
        <div>Ultime lavorazioni</div>
      </v-sheet>

      <v-divider></v-divider>

      <v-list>
        <v-list-item-group
          v-model="databaseId">
          <v-list-item
            v-for="(lavorazione) in linksLavorazioni"
            :key="lavorazione.lavorazione.id"
            link
            :value="lavorazione.lavorazione.id"
            @click="loadLavorazione(lavorazione.lavorazione)"
          >
            <v-list-item-avatar>
              <v-icon>{{ lavorazione.icon }}</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{ lavorazione.text }}</span>
                  </template>
                  <span>{{ lavorazione.text }}</span>
                </v-tooltip>
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{ lavorazione.lavorazione.updated_at | datetime }}</span>
                  </template>
                  <span>{{ lavorazione.lavorazione.updated_at | datetime }}</span>
                </v-tooltip>
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn color="error lighten-2" text x-small @click.stop="showDeleteLavorazioneDialog(lavorazione.lavorazione)"><v-icon>fas fa-trash-alt</v-icon></v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <div class="text-center" v-if="pagination.pageCount > 1">
        <v-pagination
          :value="pagination.page"
          @input="changePage"
          @next="changePage(pagination.page+1)"
          @previous="changePage(pagination.page-1)"
          :length="pagination.pageCount"
        ></v-pagination>
      </div>
    </v-navigation-drawer>
    <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title>Elimina etichetta</v-card-title>
              <v-card-text>Confermi eliminazione etichetta "<strong>{{labelToDelete ? labelToDelete.code : ''}}</strong>" ?</v-card-text>
              <v-card-actions>
                <v-btn text @click="dialogDelete = false">Annulla</v-btn>
                <v-btn color="error" text @click="deleteLabel()">Elimina</v-btn>
              </v-card-actions>
            </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteLavorazione" max-width="500px">
            <v-card>
              <v-card-title>Elimina lavorazione</v-card-title>
              <v-card-text>Confermi eliminazione lavorazione "<strong>{{lavorazioneToDelete ? lavorazioneToDelete.nome : ''}}</strong>" ?</v-card-text>
              <v-card-actions>
                <v-btn text @click="dialogDeleteLavorazione = false">Annulla</v-btn>
                <v-btn color="error" text @click="deleteLavorazione()">Elimina</v-btn>
              </v-card-actions>
            </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCamera" max-width="640px">
      <StreamBarcodeReader
          v-if="dialogCamera"
          @decode="onBarcodeDecode"
      ></StreamBarcodeReader>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import StreamBarcodeReader from "@/components/BarcodeScanner.vue";
import VueBarcode from "vue-barcode";
import _ from "lodash";
import {Howl} from 'howler';

export default {
  data: () => ({
    onAfterLoad: true,
    sound: new Howl({
            src: '/assets/beep.mp3',
            volume: 0.5,
            html5: true
        }),
    drawer: true,
    apiUrl: process.env.VUE_APP_API_URL,
    nome: '',
    dialogDelete: false,
    dialogCamera: false,
    dialogDeleteLavorazione: false,
    labelToFill: undefined,
    labelToDelete: undefined,
    lavorazioneToDelete: undefined,
    labelsChunks: [],
    databaseId: undefined,
    labels: [
      { 
        code: "",
        quantity: 1,
        format: 'code128'
      }
    ],
    formats: [
      {text: 'CODE128',  value: 'code128'},
      {text: 'EAN-13',  value: 'ean13'},
      {text: 'EAN-8', value: 'ean8'},
      {text: 'EAN-5', value: 'ean5'},
      {text: 'EAN-2', value: 'ean2'},
      {text: 'UPC', value: 'upc'},
      {text: 'ITF-14', value: 'itf14'},
      {text: 'MSI', value: 'msi'},
      {text: 'MSI10', value: 'msi10'},
      {text: 'Pharmacode', value: 'pharmacode'}
    ]
  }),
  props: {
    msg: String,
  },
  watch: {
    labels: {
      // Eseguo la generazione solo dopo mezzo secondo dall'ultimo input per non bloccare la UI
      handler: _.debounce(function() {
          this.labelsChunks = _.chunk(_.reduce(this.labels, (a,v) => {
            for(let i = 0; i < v.quantity; i++){
              if(v.code){
                a.push({code: v.code, format: v.format})
              }
            }
            return a;
          }, []), 24); // Dividiamo in 24 elementi per pagina
          if(this.onAfterLoad){
            this.onAfterLoad = false
          } else {
            this.queueUpdateLavorazione();
          }
      }, 500),
      deep: true
    },
    'lavorazioneCorrente.id': function(val) {
      this.databaseId = val
    }
  },
  computed: {
    ...mapGetters({
      lavorazioni: 'lavorazioni/lavorazioni',
      lavorazioneCorrente: 'lavorazioni/lavorazioneCorrente',
      pagination: 'lavorazioni/pagination'
    }),
    linksLavorazioni(){
      return _.map(this.lavorazioni, l => ({icon: 'far fa-list-alt', text: l.nome, lavorazione: l}));
    },
    queueUpdateLavorazione(){
      return _.debounce(this.updateLavorazione, 3000)
    }
  },
  methods: {
    playBeep() {
      this.sound.play();
    },
    print() {
      this.$refs.printHtml.value = this.$refs.barcodes.innerHTML;
      this.$refs.printForm.submit();
    },
    addLabel() {
      this.labels.push({ 
        code: "",
        quantity: 1,
        format: 'code128'
      })
    },
    increment() {
      this.quantity += 1;
    },
    decrement() {
      this.quantity -= 1;
    },
    showDeleteDialog(item) {
        this.labelToDelete = item;
        this.dialogDelete = true;
    },
    showDeleteLavorazioneDialog(item) {
        this.lavorazioneToDelete = item;
        this.dialogDeleteLavorazione = true;
    },
    showCameraDialog(item) {
        this.labelToFill = item;
        this.dialogCamera = true;
    },
    deleteLabel() {
        const index = this.labels.indexOf(this.labelToDelete)    
        this.labels.splice(index, 1)
        this.labelToDelete = undefined
        this.dialogDelete = false
    },
    onBarcodeDecode (result) {
      this.playBeep();
      this.labelToFill.code = result;
      this.labelToFill = undefined;
      this.dialogCamera = false;
    },
    create () {
      this.$store.dispatch('lavorazioni/create', {
        nome: this.nome,
        etichette: this.labels
      })
    },
    loadLavorazione (lavorazione) {
      this.onAfterLoad = true
      this.queueUpdateLavorazione.cancel()
      this.databaseId = lavorazione.id
      this.labels = lavorazione.etichette
      this.nome = lavorazione.nome
    },
    deleteLavorazione () {
      this.$store.dispatch('lavorazioni/delete', this.lavorazioneToDelete.id)
      this.lavorazioneToDelete = undefined
      this.dialogDeleteLavorazione = false
    },
    updateLavorazione() {
      if(this.databaseId){
        this.$store.dispatch('lavorazioni/update', {
          id: this.databaseId,
          nome: this.nome,
          etichette: this.labels
        })
      } else {
        this.create()
      }
    },
    changePage(page) {
      this.$store.dispatch("lavorazioni/changeOptions", {page: page});
    },
  },
  components: {
    barcode: VueBarcode,
    StreamBarcodeReader: StreamBarcodeReader,
  },
  mounted(){
    this.$store.dispatch('lavorazioni/refresh')
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

/deep/ .centered-input input {
  text-align: center
}

#barcodes {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
.page {
  background-color: #fff;
  box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  width: 100%;
  padding-top: 4mm;
  padding-bottom: 4mm;
  margin-top: 30px;
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0px 0px;
  justify-items: center;
  grid-template-areas: ". . .";
}

/deep/ .vue-barcode-element {
  width: 100% !important;
}

.page-break {
  page-break-after: always;
}
</style>
