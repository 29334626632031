import axios from 'axios'
import _ from 'lodash'

const state = {
  magazzino: [],
  oggettoCorrente: undefined,
  pagination: {
    page: 1,
    pageCount: 0
  },
  options: {},
  loading: true
}

// getters
const getters = {
  magazzino: state => {
    return state.magazzino
  },
  oggettoCorrente: state => {
    return state.oggettoCorrente
  },
  pagination: state => {
    return state.pagination
  },
}

// actions
const actions = {
  refresh({
    commit,
    state
  }) {
    let sort = _.map(state.options.sortBy, (fieldName, fieldIndex) => (`${fieldName}:${state.options.sortDesc[fieldIndex] ? 'desc' : 'asc'}`))
    axios.get('/magazzino', {
        params: {
          sort: sort,
          limit: state.options.itemsPerPage,
          page: state.options.page,
          q: state.options.q
        }
      })
      .then((response) => {
        commit('set', response.data)
      })
  },
  get({
    commit
  }, id) {
    commit('setOggettoCorrente', undefined)
    axios.get(`/magazzino/${id}`)
      .then((response) => {
        commit('setOggettoCorrente', response.data)
      })
  },
  create({
    dispatch,
    commit
  }, oggetto) {
    axios.post('/magazzino', oggetto)
      .then((response) => {
        commit('setOggettoCorrente', response.data)
        dispatch('refresh')
      })
  },
  remove({
    dispatch,
    commit
  }, oggetto) {
    axios.post('/magazzino/remove', oggetto)
      .then((response) => {
        commit('setOggettoCorrente', response.data)
        dispatch('refresh')
      })
  },
  update({
    dispatch
  }, oggetto) {
    axios.put(`/magazzino/${oggetto.id}`, oggetto)
      .then(() => {
        dispatch('refresh')
      })
  },
  delete({
    dispatch
  }, id) {
    axios.delete(`/magazzino/${id}`)
      .then(() => {
        dispatch('refresh')
      })
  },
  changeOptions({
    dispatch,
    commit
  }, options) {
    commit('setOptions', options)
    dispatch('refresh')
  }
}

// mutations
const mutations = {
  set(state, magazzino) {
    state.magazzino = magazzino.data
    state.pagination = magazzino.pagination
  },
  setOggettoCorrente(state, oggettoCorrente) {
    state.oggettoCorrente = oggettoCorrente
  },
  setOptions(state, options) {
    state.options = options
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}