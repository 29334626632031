import Vue from 'vue'

import {
  format,
  parseISO
} from 'date-fns'
import it from 'date-fns/locale/it'

Vue.filter('currency', function (value) {
  if (value) {
    if (typeof value !== 'number') {
      return value
    }
    var formatter = new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR',
      minimumFractionDigits: 2
    })
    return formatter.format(value)
  }
})

Vue.filter('datetime', function (value) {
  if (value) {
    return format(parseISO(String(value)), 'dd MMMM yyyy HH:mm', {
      locale: it
    })
  }
})

Vue.filter('title', function (value) {
  if (value) {
    let newValue = value
    newValue = newValue.replace(/[_-]+/g, ' ')
    // Title
    newValue =
      newValue.charAt(0).toUpperCase() + newValue.substr(1).toLowerCase()
    return newValue
  }
})

Vue.filter('date', function (value) {
  if (value) {
    return format(parseISO(String(value)), 'dd MMMM yyyy', {
      locale: it
    })
  }
})

Vue.filter('time', function (value) {
  if (value) {
    return format(parseISO(String(value)), 'HH:mm', {
      locale: it
    })
  }
})