<template>
  <v-container>
    <v-row justify="end">
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
    </v-row>
    <v-row align="center" justify="center">
      <h2>Ordini</h2>
    </v-row>
    <v-row align="end">
      <v-col cols="12" md="6">
        <v-combobox
          :items="prefissi"
          label="Prefisso"
          v-model="prefisso"
          autoSelectFirst
          @change="queueUpdateOrdine"
          hide-details="auto"
        ></v-combobox>
      </v-col>
      <v-col cols="12" md="6">
        <v-text-field
          label="Numero ordine"
          v-model="numero"
          hide-details="auto"
          @keyup="queueUpdateOrdine"
        ></v-text-field>
      </v-col>
      <v-col cols="3" md="1">
        <v-btn
          color="primary"
          text
          @click="updateOrdine"
        >
          <v-icon class="mr-2">far fa-save</v-icon> Salva
        </v-btn>
      </v-col>
      <v-col cols="3" md="1" v-if="databaseId">
        <v-btn
          class="ml-3"
          color="green"
          text
          target="_blank"
          :href="`${apiUrl}/ordini/excel/${databaseId}`"
        >
          <v-icon class="mr-2">fas fa-file-excel</v-icon> Excel
        </v-btn>
      </v-col>
      <v-col cols="6" md="1">
        <form ref="printForm" :action="`${apiUrl}/print`" method="post" target="_blank" @submit.prevent="print">
          <input type="hidden" name="html" id="html" ref="printHtml">
          <input type="hidden" name="cellHeight" id="cellHeight" value="104mm">
          <input type="hidden" name="rows" id="rows" value="2">
          <input type="hidden" name="columns" id="columns" value="2">
          <input type="hidden" name="landscape" id="landscape" value="true">
          <input type="hidden" name="marginTop" id="marginTop" value="0.5mm">
          <input type="hidden" name="marginBottom" id="marginBottom" value="0">
          <input type="hidden" name="marginRight" id="marginRight" value="0">
          <input type="hidden" name="marginLeft" id="marginLeft" value="0">
          <v-btn color="primary" class="ml-3" type="submit" text><v-icon class="mr-2">fas fa-print</v-icon> Stampa</v-btn>
        </form>
      </v-col>
    </v-row>
    <v-card :color="colors[i]" class="mt-3 mb-3" v-for="(scatola, i) in scatole" :key="i">
      <v-card-title>
        <h4 class="mt-0" v-if="!scatola.nome_personalizzato"><v-icon class="mr-3">fas fa-box-open</v-icon> Scatola {{i+1}}</h4>
        <v-text-field
          v-else
          prepend-icon="fas fa-box-open"
          label="Nome scatola"
          v-model="scatola.nome"
          hide-details="auto"
        ></v-text-field>
        <v-switch
          v-model="scatola.nome_personalizzato"
          label="Nome personalizzato"
          class="ml-3"
        ></v-switch>
      </v-card-title>
      <v-card-text>
        <v-row>
        <v-col v-for="(oggetto,i) in scatola.oggetti" :key="i" cols="12" sm="12" md="6">
        <v-card class="mt-1">
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  label="Codice"
                  v-model="oggetto.codice"
                  hide-details="auto"
                  prepend-icon="fas fa-camera"
                  @click:prepend="showCameraDialog(oggetto)"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="12" md="6">
                <v-text-field
                  v-model.number="oggetto.quantita"
                  type="number"
                  label="Quantità"
                  class="centered-input"
                  append-outer-icon="fa-plus"
                  @click:append-outer="oggetto.quantita+=1"
                  prepend-icon="fa-minus"
                  @click:prepend="oggetto.quantita-=1"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-btn
              color="error"
              text
              @click="showDeleteOggettoDialog(scatola, oggetto)"
            >
              Elimina oggetto
            </v-btn>
          </v-card-actions>
        </v-card>
        </v-col>
        </v-row>
        <v-row align="center" justify="center" class="mt-3">
          <v-btn @click="addOggetto(scatola)">Aggiungi oggetto</v-btn>
        </v-row>
        <v-row justify="center" class="mt-3">
          <v-btn @click="showScanContinuoDialog(scatola)"><v-icon class="mr-2">fas fa-barcode</v-icon> Aggiungi con scan</v-btn>
        </v-row>
        <v-row>
          <v-col sm="6">
            <v-text-field
              label="Peso"
              v-model.number="scatola.peso"
              hide-details="auto"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="error"
          text
          @click="showDeleteScatolaDialog(scatola)"
        >
        <v-icon class="mr-3" color="error lighten-1">fas fa-box-open</v-icon>  Elimina scatola
        </v-btn>
    </v-card-actions>
    </v-card>
    <v-row class="mt-5 mb-5" align="center" justify="center">
      <v-btn @click="addScatola">Aggiungi scatola</v-btn>
    </v-row>
    <v-row class="mt-7 mb-3" align="center" justify="center">
      <h2>Anteprima</h2>
    </v-row>
    <v-row class="mt-3 mb-3" align="center" justify="center">
      <div id="printarea" ref="printarea">
        <div v-for="(scatoleChunks, i) in labelsChunks" :key="i" class="page">
          <div v-for="(scatola, i) in scatoleChunks" :key="i" class="text-center" style="display: flex; flex-direction: column;">
            <h2 style="margin-top: 2mm; margin-bottom: 2mm;">{{prefisso}} {{numero}} - <span style="font-weight: 100"><span v-if="!scatola.nome_personalizzato">scatola {{scatole.indexOf(scatola) + 1}}</span><span v-else>{{scatola.nome}}</span> - {{scatola.peso}}kg</span></h2>
            <table style="width: 70%; margin: 0 auto; flex-grow: 1;">
              <thead>
                <tr>
                  <th style="text-align: left">Codice</th>
                  <th>Quantità</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(oggetto, i) in scatola.oggetti" :key="i">
                  <td style="text-align: left">{{oggetto.codice}}</td>
                  <td style="text-align: center">{{oggetto.quantita}}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </v-row>
    <v-navigation-drawer
      width="330"
      clipped
      v-model="drawer"
      right
      app
    >
      <v-sheet
        color="grey lighten-4"
        class="pa-4"
      >
        <div>Ultimi ordini</div>
      </v-sheet>

      <v-divider></v-divider>

      <v-list two-line>
        <v-list-item-group
          v-model="databaseId">
          <v-list-item
            v-for="(ordine) in linksOrdini"
            :key="ordine.ordine.id"
            link
            :value="ordine.ordine.id"
            @click="loadOrdine(ordine.ordine)"
          >
            <v-list-item-avatar>
              <v-icon>{{ ordine.icon }}</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{ ordine.text }}</span>
                  </template>
                  <span>{{ ordine.text }}</span>
                </v-tooltip>
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">{{ ordine.ordine.updated_at | datetime }}</span>
                  </template>
                  <span>{{ ordine.ordine.updated_at | datetime }}</span>
                </v-tooltip>
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn color="error lighten-2" text x-small @click.stop="showDeleteOrdineDialog(ordine.ordine)"><v-icon>fas fa-trash-alt</v-icon></v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-list>
      <div class="text-center" v-if="pagination.pageCount > 1">
        <v-pagination
          :value="pagination.page"
          @input="changePage"
          @next="changePage(pagination.page+1)"
          @previous="changePage(pagination.page-1)"
          :length="pagination.pageCount"
        ></v-pagination>
      </div>
    </v-navigation-drawer>
    <v-dialog v-model="dialogDeleteScatola" max-width="500px">
      <v-card>
        <v-card-title>Elimina scatola</v-card-title>
        <v-card-text>Confermi eliminazione "<strong>Scatola {{scatolaToDelete ? scatole.indexOf(scatolaToDelete) + 1 : ''}}</strong>" ?</v-card-text>
        <v-card-actions>
          <v-btn text @click="dialogDeleteScatola = false">Annulla</v-btn>
          <v-btn color="error" text @click="deleteScatola()">Elimina</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteOggetto" max-width="500px">
      <v-card>
        <v-card-title>Elimina oggetto da scatola</v-card-title>
        <v-card-text>Confermi eliminazione oggetto dal codice "<strong>{{oggettoToDelete ? oggettoToDelete.codice : ''}}</strong>" ?</v-card-text>
        <v-card-actions>
          <v-btn text @click="dialogDeleteOggetto = false">Annulla</v-btn>
          <v-btn color="error" text @click="deleteOggetto()">Elimina</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteOrdine" max-width="500px">
      <v-card>
        <v-card-title>Elimina ordine</v-card-title>
        <v-card-text>Confermi eliminazione ordine "<strong>{{ordineToDelete ? ordineToDelete.numero : ''}}</strong>" ?</v-card-text>
        <v-card-actions>
          <v-btn text @click="dialogDeleteOrdine = false">Annulla</v-btn>
          <v-btn color="error" text @click="deleteOrdine()">Elimina</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCamera" max-width="640px">
      <StreamBarcodeReader
          v-if="dialogCamera"
          @decode="onBarcodeDecode"
      ></StreamBarcodeReader>
    </v-dialog>
    <v-dialog v-model="dialogScanContinuo" max-width="640px">
      <StreamBarcodeReader
          v-if="dialogScanContinuo"
          @decode="onScanContinuoDecode"
      ></StreamBarcodeReader>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import StreamBarcodeReader from "@/components/BarcodeScanner.vue";
import _ from "lodash";
import toMaterialStyle from 'material-color-hash';
import {Howl} from 'howler';

export default {
  data: () => ({
    onAfterLoad: true,
    sound: new Howl({
            src: '/assets/beep.mp3',
            volume: 0.5,
            html5: true
        }),
    drawer: true,
    apiUrl: process.env.VUE_APP_API_URL,
    numero: '',
    prefisso: 'ORDINE',
    prefissi: ['ORDINE', 'PROGRAMMA'],
    dialogScanContinuo: false,
    dialogDeleteOrdine: false,
    dialogDeleteScatola: false,
    dialogDeleteOggetto: false,
    dialogCamera: false,
    oggettoToFill: undefined,
    scatolaToFill: undefined,
    oggettoToDelete: undefined,
    ordineToDelete: undefined,
    scatolaOggettoToDelete: undefined,
    scatolaToDelete: undefined,
    labelsChunks: [],
    databaseId: undefined,
    scatole: [
      { 
        codice: "",
        peso: 0.0,
        oggetti: [{
          codice: "",
          quantita: 1
        }]
      }
    ]
  }),
  props: {
    msg: String,
  },
  watch: {
    scatole: {
      // Eseguo la generazione solo dopo mezzo secondo dall'ultimo input per non bloccare la UI
      handler: _.debounce(function() {
          this.labelsChunks = _.chunk(this.scatole, 4); // Dividiamo in 4 elementi per pagina
          if(this.onAfterLoad){
            this.onAfterLoad = false
          } else {
            this.queueUpdateOrdine();
          }
      }, 500),
      deep: true
    },
    'ordineCorrente.id': function(val) {
      this.databaseId = val
    }
  },
  computed: {
    ...mapGetters({
      ordini: 'ordini/ordini',
      ordineCorrente: 'ordini/ordineCorrente',
      pagination: 'ordini/pagination'
    }),
    linksOrdini(){
      return _.map(this.ordini, o => ({icon: 'far fa-list-alt', text: o.numero, ordine: o}));
    },
    colors(){
      return _.map(this.scatole, (_,i) => toMaterialStyle(`Scatola ${i+1}`, 50).backgroundColor)
    },
    queueUpdateOrdine(){
      return _.debounce(this.updateOrdine, 3000)
    }
  },
  methods: {
    playBeep() {
      this.sound.play();
    },
    print() {
      this.$refs.printHtml.value = this.$refs.printarea.innerHTML;
      this.$refs.printForm.submit();
    },
    addScatola() {
      this.scatole.push({ 
        codice: "",
        peso: 0.0,
        oggetti: [{
          codice: "",
          quantita: 1
        }]
      })
    },
    addOggetto(scatola) {
      scatola.oggetti.push({
        codice: "",
        quantita: 1
      })
    },
    increment() {
      this.quantity += 1;
    },
    decrement() {
      this.quantity -= 1;
    },
    showScanContinuoDialog(scatola) {
        this.scatolaToFill = scatola;
        this.dialogScanContinuo = true;
    },
    showDeleteOrdineDialog(scatola) {
        this.ordineToDelete = scatola;
        this.dialogDeleteOrdine = true;
    },
    showDeleteScatolaDialog(scatola) {
        this.scatolaToDelete = scatola;
        this.dialogDeleteScatola = true;
    },
    showDeleteOggettoDialog(scatola, oggetto) {
        this.oggettoToDelete = oggetto;
        this.scatolaOggettoToDelete = scatola;
        this.dialogDeleteOggetto = true;
    },
    showCameraDialog(item) {
        this.oggettoToFill = item;
        this.dialogCamera = true;
    },
    deleteScatola() {
        const index = this.scatole.indexOf(this.scatolaToDelete)    
        this.scatole.splice(index, 1)
        this.scatolaToDelete = undefined
        this.dialogDeleteScatola = false
    },
    deleteOrdine() {
      this.$store.dispatch('ordini/delete', this.ordineToDelete.id)
      this.ordineToDelete = undefined
      this.dialogDeleteOrdine = false
    },
    deleteOggetto() {
        const index = this.scatolaOggettoToDelete.oggetti.indexOf(this.oggettoToDelete)    
        this.scatolaOggettoToDelete.oggetti.splice(index, 1)
        this.scatolaOggettoToDelete = undefined
        this.oggettoToDelete = undefined
        this.dialogDeleteOggetto = false
    },
    onBarcodeDecode (result) {
      this.playBeep();
      this.oggettoToFill.codice = result;
      this.oggettoToFill = undefined;
      this.dialogCamera = false;
    },
    onScanContinuoDecode (result) {
      this.playBeep()
      const oggetto = _.find(this.scatolaToFill.oggetti, {codice: result})
      if(oggetto){
        oggetto.quantita += 1
      } else {
        this.scatolaToFill.oggetti.push({
        codice: result,
        quantita: 1
      })
      }
    },
    create () {
      this.$store.dispatch('ordini/create', {
        numero: this.numero,
        prefisso: this.prefisso,
        scatole: this.scatole
      })
    },
    loadOrdine(ordine) {
      this.onAfterLoad = true
      this.queueUpdateOrdine.cancel()
      this.databaseId = ordine.id
      this.scatole = ordine.scatole
      this.numero = ordine.numero
      this.prefisso = ordine.prefisso
    },
    updateOrdine() {
      if(this.databaseId){
        this.$store.dispatch('ordini/update', {
          id: this.databaseId,
          numero: this.numero,
          prefisso: this.prefisso,
          scatole: this.scatole
        })
      } else {
        this.create()
      }
    },
    changePage(page) {
      this.$store.dispatch("ordini/changeOptions", {page: page});
    },
  },
  components: {
    StreamBarcodeReader: StreamBarcodeReader,
  },
  mounted(){
    this.$store.dispatch('ordini/refresh')
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

/deep/ .centered-input input {
  text-align: center
}

#printarea {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
.page {
  background-color: #fff;
  box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19);
  width: 100%;
  padding-top: 4mm;
  padding-bottom: 4mm;
  margin-top: 30px;
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0px 0px;
  justify-items: center;
  grid-template-areas: ". .";
}

/deep/ .vue-barcode-element {
  width: 100% !important;
}

.page-break {
  page-break-after: always;
}
</style>
