import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import dialog from './modules/dialog'
import lavorazioni from './modules/lavorazioni'
import ordini from './modules/ordini'
import magazzino from './modules/magazzino'
import clienti from './modules/clienti'
import users from './modules/users'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    dialog,
    lavorazioni,
    magazzino,
    ordini,
    clienti,
    users,
  }
})
