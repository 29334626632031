import axios from 'axios'
import NProgress from 'nprogress'
import router from '../router'
import store from '../store'

import 'nprogress/nprogress.css'

axios.interceptors.request.use(config => {
  NProgress.start()
  return config
})

// before a response is returned stop nprogress
axios.interceptors.response.use(
  response => {
    NProgress.done()
    return response
  },
  function (error) {
    // Do something with response error
    store.dispatch('dialog/show', {
      title: error.response.data.error,
      message: error.response.data.message,
      type: 'danger'
    })
    NProgress.done()
    return Promise.reject(error)
  }
)

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start()
  }
  next()
})

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done()
})
