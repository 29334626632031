<template>
    <div class="box">
        <div class="scanner-container">
            <div v-show="!isLoading">
                <video poster="data:image/gif,AAAA" ref="scanner"></video>
                <div class="overlay-element"></div>
                <div class="laser"></div>
            </div>
        </div>
        <v-select :items="devices" v-model="device"></v-select>
        <v-btn text @click="reset">Reset</v-btn>
    </div>
</template>

<script>
import { BrowserMultiFormatReader, Exception } from "@zxing/library";
export default {
    name: "stream-barcode-reader",
    data() {
        return {
            isLoading: true,
            codeReader: new BrowserMultiFormatReader(null, 2000), // 2 seconds wait between successfull reads
            devices: [],
            device: localStorage.getItem('preferedCamera') || undefined,
            isMediaStreamAPISupported:
                navigator &&
                navigator.mediaDevices &&
                "enumerateDevices" in navigator.mediaDevices
        };
    },
    mounted() {
        const that = this;
        if (!this.isMediaStreamAPISupported) {
            throw new Exception("Media Stream API is not supported");
        }
        this.codeReader.listVideoInputDevices()
            .then(videoInputDevices => {
                videoInputDevices.forEach(device => that.devices.push({text: device.label, value: device.deviceId}));
            })
            .catch(err => console.error(err));
        this.start();
        this.$refs.scanner.oncanplay = () => {
            this.isLoading = false;
            this.$emit("loaded");
        };
    },
    beforeDestroy() {
        this.codeReader.reset();
    },
    watch: {
        device(device){
            localStorage.setItem('preferedCamera', device)
            this.codeReader.reset();
            this.start();
        }
    },
    methods: {
        reset() {
            this.device = undefined;
            localStorage.setItem('preferedCamera', undefined)
            this.codeReader.reset();
            this.start();
        },
        start() {
            this.codeReader.decodeFromVideoDevice(
                this.device,
                this.$refs.scanner,
                (result) => {
                    if (result) {
                        this.$emit("decode", result.text);
                    }
                }
            );
        }
    }
};
</script>

<style scoped>
.box {
    background-color: #fff;
}
video {
    max-width: 100%;
    max-height: 100%;
}
.scanner-container {
    position: relative;
}
.overlay-element {
    position: absolute;
    top: 0;
    width: 100%;
    height: 99%;
    background: rgba(30, 30, 30, 0.5);
    -webkit-clip-path: polygon(
        0% 0%,
        0% 100%,
        20% 100%,
        20% 20%,
        80% 20%,
        80% 80%,
        20% 80%,
        20% 100%,
        100% 100%,
        100% 0%
    );
    clip-path: polygon(
        0% 0%,
        0% 100%,
        20% 100%,
        20% 20%,
        80% 20%,
        80% 80%,
        20% 80%,
        20% 100%,
        100% 100%,
        100% 0%
    );
}
.laser {
    width: 60%;
    margin-left: 20%;
    background-color: tomato;
    height: 1px;
    position: absolute;
    top: 40%;
    z-index: 2;
    box-shadow: 0 0 4px red;
    -webkit-animation: scanning 2s infinite;
    animation: scanning 2s infinite;
}
@-webkit-keyframes scanning {
    50% {
        -webkit-transform: translateY(75px);
        transform: translateY(75px);
    }
}
@keyframes scanning {
    50% {
        -webkit-transform: translateY(75px);
        transform: translateY(75px);
    }
}
</style>