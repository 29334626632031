<template>
  <v-container>
    <v-row align="center" justify="center">
      <h2>Magazzino</h2>
    </v-row>

    <v-card class="mt-3 mb-3">
      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-text-field
              label="Cerca"
              :value="search"
              @input="
                (e) => {
                  search = e;
                  queueSearch();
                }
              "
              hide-details="auto"
              prepend-icon="fas fa-camera"
              append-icon="fas fa-search"
              @click:prepend="
                showCameraDialog((result) => {
                  search = result;
                  queueSearch();
                })
              "
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-row class="justify-center">
      <v-btn
        color="success"
        text
        @click="
          numero = search;
          dialogAdd = true;
        "
        ><v-icon class="mr-2">fas fa-plus</v-icon> Aggiungi</v-btn
      >
      <v-btn
        color="error"
        text
        @click="
          numero = search;
          dialogRemove = true;
        "
        ><v-icon class="mr-2">fas fa-minus</v-icon> Rimuovi</v-btn
      >
    </v-row>

    <v-card class="mt-3 mb-3">
      <v-list>
        <v-list-item-group v-model="databaseId">
          <v-list-item
            v-for="oggetto in magazzino"
            :key="oggetto.id"
            link
            :value="oggetto.id"
          >
            <v-list-item-avatar>
              <v-icon>{{ oggetto.icon }}</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on"
                      ><span class="mr-2"><v-icon>fas fa-barcode</v-icon></span>
                      <strong>{{ oggetto.numero }}</strong>
                      <v-chip v-if="oggetto.variante" x-small color="primary" class="ml-2 mr-2">Var: <strong>{{ oggetto.variante }}</strong></v-chip>
                      <span class="ml-2 mr-2"
                        ><v-icon>fas fa-arrows-alt-h</v-icon></span
                      >
                      Quantità: <strong>{{ oggetto.quantita }}</strong></span
                    >
                  </template>
                  <span
                    ><span class="mr-2"
                      ><v-icon color="white">fas fa-barcode</v-icon></span
                    >
                    <strong>{{ oggetto.numero }}</strong>
                    <v-chip v-if="oggetto.variante" x-small color="primary" class="ml-2 mr-2">Var: <strong>{{ oggetto.variante }}</strong></v-chip>
                    <span class="ml-2 mr-2"
                      ><v-icon color="white">fas fa-arrows-alt-h</v-icon></span
                    >
                    Quantità: <strong>{{ oggetto.quantita }}</strong></span
                  >
                </v-tooltip>
              </v-list-item-title>
              <v-list-item-subtitle>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <span v-bind="attrs" v-on="on">
                      <span v-if="oggetto.note"
                        ><pre>{{ oggetto.note }}</pre></span
                      >
                      {{ oggetto.updated_at | datetime }}</span
                    >
                  </template>
                  <span
                    ><span v-if="oggetto.note"
                      ><pre>{{ oggetto.note }}</pre></span
                    >{{ oggetto.updated_at | datetime }}</span
                  >
                </v-tooltip>
              </v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn
                color="error lighten-2"
                text
                x-small
                @click.stop="showDeleteOggettoDialog(oggetto)"
                ><v-icon>fas fa-trash-alt</v-icon></v-btn
              >
            </v-list-item-action>
          </v-list-item>
          <v-list-item v-if="!pagination.rowCount" class="justify-center">
            <h2 class="grey--text text--darken-1">
              <em>Nessun oggetto trovato</em>
            </h2>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
    <div class="justify-center" v-if="pagination.pageCount > 1">
      <v-pagination
        :value="pagination.page"
        @input="changePage"
        @next="changePage(pagination.page + 1)"
        @previous="changePage(pagination.page - 1)"
        :length="pagination.pageCount"
      ></v-pagination>
    </div>
    <v-dialog v-model="dialogAdd" max-width="500px">
      <v-card>
        <v-card-title>Aggiungi oggetto</v-card-title>
        <v-card-text
          ><v-text-field
            label="Numero"
            v-model="numero"
            hide-details="auto"
            prepend-icon="fas fa-camera"
            @click:prepend="showCameraDialog((result) => (numero = result))"
          ></v-text-field>
          <v-text-field
            label="Variante"
            v-model="variante"
            hide-details="auto"
          ></v-text-field>
          <v-text-field
            v-model.number="quantita"
            type="number"
            label="Quantità"
            class="centered-input"
            append-outer-icon="fa-plus"
            @click:append-outer="quantita += 1"
            prepend-icon="fa-minus"
            @click:prepend="quantita -= 1"
          ></v-text-field>
          <v-text-field
            label="Note"
            v-model="note"
            hide-details="auto"
          ></v-text-field
        ></v-card-text>
        <v-card-actions>
          <v-btn text @click="dialogAdd = false">Annulla</v-btn>
          <v-btn color="success" text @click="create()">Aggiungi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogRemove" max-width="500px">
      <v-card>
        <v-card-title>Rimuovi oggetto</v-card-title>
        <v-card-text
          ><v-text-field
            label="Numero"
            v-model="numero"
            hide-details="auto"
            prepend-icon="fas fa-camera"
            @click:prepend="showCameraDialog((result) => (numero = result))"
          ></v-text-field
          ><v-text-field
            label="Variante"
            v-model="variante"
            hide-details="auto"
          ></v-text-field
          ><v-text-field
            v-model.number="quantita"
            type="number"
            label="Quantità"
            class="centered-input"
            append-outer-icon="fa-plus"
            @click:append-outer="quantita += 1"
            prepend-icon="fa-minus"
            @click:prepend="quantita -= 1"
          >
          </v-text-field
        ></v-card-text>
        <v-card-actions>
          <v-btn text @click="dialogRemove = false">Annulla</v-btn>
          <v-btn color="error" text @click="remove()">Rimuovi</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogDeleteOggetto" max-width="500px">
      <v-card>
        <v-card-title>Elimina oggetto</v-card-title>
        <v-card-text
          >Confermi eliminazione oggetto "<strong>{{
            oggettoToDelete ? oggettoToDelete.numero : ""
          }}</strong
          >"? L'operazione cancellerà definitivamente l'oggetto dal magazzino
          oltre che il suo conteggio, come se non fosse mai stato
          inserito.</v-card-text
        >
        <v-card-actions>
          <v-btn text @click="dialogDeleteOggetto = false">Annulla</v-btn>
          <v-btn color="error" text @click="deleteOggetto()">Elimina</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialogCamera" max-width="640px">
      <StreamBarcodeReader
        v-if="dialogCamera"
        @decode="onBarcodeDecode"
      ></StreamBarcodeReader>
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import StreamBarcodeReader from "@/components/BarcodeScanner.vue";
import _ from "lodash";
import { Howl } from "howler";

export default {
  data: () => ({
    onAfterLoad: true,
    search: undefined,
    numero: undefined,
    variante: undefined,
    searchPending: false,
    quantita: 0,
    note: "",
    sound: new Howl({
      src: "/assets/beep.mp3",
      volume: 0.5,
      html5: true,
    }),
    drawer: true,
    apiUrl: process.env.VUE_APP_API_URL,
    dialogDelete: false,
    dialogAdd: false,
    dialogRemove: false,
    dialogCamera: false,
    cameraCallback: undefined,
    dialogDeleteOggetto: false,
    labelToFill: undefined,
    labelToDelete: undefined,
    oggettoToDelete: undefined,
    labelsChunks: [],
    databaseId: undefined,
  }),
  props: {
    msg: String,
  },
  watch: {},
  computed: {
    ...mapGetters({
      loading: "magazzino/loading",
      magazzino: "magazzino/magazzino",
      oggettCorrente: "magazzino/oggettoCorrente",
      pagination: "magazzino/pagination",
    }),
    queueUpdateMagazzino() {
      return _.debounce(this.updateMagazzino, 3000);
    },
    queueSearch() {
      return _.debounce(this.searchOggetto, 500);
    },
  },
  methods: {
    playBeep() {
      this.sound.play();
    },
    addLabel() {
      this.labels.push({
        code: "",
        quantity: 1,
        format: "code128",
      });
    },
    increment() {
      this.quantity += 1;
    },
    decrement() {
      this.quantity -= 1;
    },
    showDeleteDialog(item) {
      this.labelToDelete = item;
      this.dialogDelete = true;
    },
    showDeleteOggettoDialog(item) {
      this.oggettoToDelete = item;
      this.dialogDeleteOggetto = true;
    },
    showCameraDialog(callback) {
      this.cameraCallback = callback;
      this.dialogCamera = true;
    },
    onBarcodeDecode(result) {
      this.playBeep();
      this.cameraCallback(result);
      this.dialogCamera = false;
    },
    create() {
      this.$store.dispatch("magazzino/create", {
        numero: this.numero,
        variante: this.variante,
        quantita: this.quantita,
        note: this.note,
      });
    },
    remove() {
      this.$store.dispatch("magazzino/remove", {
        numero: this.numero,
        variante: this.variante,
        quantita: this.quantita,
      });
    },
    loadOggetto(oggetto) {
      this.onAfterLoad = true;
      this.queueUpdateOggetto.cancel();
      this.databaseId = oggetto.id;
      this.labels = oggetto.etichette;
      this.nome = oggetto.nome;
    },
    deleteOggetto() {
      this.$store.dispatch("magazzino/delete", this.oggettoToDelete.id);
      this.oggettoToDelete = undefined;
      this.dialogDeleteOggetto = false;
    },
    updateMagazzino() {
      if (this.databaseId) {
        this.$store.dispatch("magazzino/update", {
          id: this.databaseId,
          numero: this.numero,
          variante: this.variante,
          quantita: this.quantita,
          note: this.note,
        });
      } else {
        this.create();
      }
    },
    changePage(page) {
      this.$store.dispatch("magazzino/changeOptions", { page: page });
    },
    searchOggetto() {
      this.$store.dispatch("magazzino/changeOptions", { q: this.search });
    },
  },
  components: {
    StreamBarcodeReader: StreamBarcodeReader,
  },
  mounted() {
    this.$store.dispatch("magazzino/refresh");
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

/deep/ .centered-input input {
  text-align: center;
}

#barcodes {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
}
.page {
  background-color: #fff;
  box-shadow: 0 10px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 100%;
  padding-top: 4mm;
  padding-bottom: 4mm;
  margin-top: 30px;
  margin-bottom: 30px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0px 0px;
  justify-items: center;
  grid-template-areas: ". . .";
}

/deep/ .vue-barcode-element {
  width: 100% !important;
}

.page-break {
  page-break-after: always;
}
</style>
