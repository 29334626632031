import axios from 'axios'
import _ from 'lodash'

const state = {
  lavorazioni: [],
  lavorazioneCorrente: undefined,
  pagination: {
    page: 1,
    pageCount: 0
  },
  options: {}
}

// getters
const getters = {
  lavorazioni: state => {
    return state.lavorazioni
  },
  lavorazioneCorrente: state => {
    return state.lavorazioneCorrente
  },
  pagination: state => {
    return state.pagination
  },
}

// actions
const actions = {
  refresh({
    commit,
    state
  }) {
    let sort = _.map(state.options.sortBy, (fieldName, fieldIndex) => (`${fieldName}:${state.options.sortDesc[fieldIndex] ? 'desc' : 'asc'}`))
    axios.get('/lavorazioni', {
        params: {
          sort: sort,
          limit: state.options.itemsPerPage,
          page: state.options.page,
          q: state.options.q
        }
      })
      .then((response) => {
        commit('set', response.data)
      })
  },
  get({
    commit
  }, id) {
    commit('setLavorazioneCorrente', undefined)
    axios.get(`/lavorazioni/${id}`)
      .then((response) => {
        commit('setLavorazioneCorrente', response.data)
      })
  },
  create({
    dispatch,
    commit
  }, lavorazione) {
    axios.post('/lavorazioni', lavorazione)
      .then((response) => {
        commit('setLavorazioneCorrente', response.data)
        dispatch('refresh')
      })
  },
  update({
    dispatch
  }, lavorazione) {
    axios.put(`/lavorazioni/${lavorazione.id}`, lavorazione)
      .then(() => {
        dispatch('refresh')
      })
  },
  delete({
    dispatch
  }, id) {
    axios.delete(`/lavorazioni/${id}`)
      .then(() => {
        dispatch('refresh')
      })
  },
  changeOptions({
    dispatch,
    commit
  }, options) {
    commit('setOptions', options)
    dispatch('refresh')
  }
}

// mutations
const mutations = {
  set(state, lavorazioni) {
    state.lavorazioni = lavorazioni.data
    state.pagination = lavorazioni.pagination
  },
  setLavorazioneCorrente(state, lavorazioneCorrente) {
    state.lavorazioneCorrente = lavorazioneCorrente
  },
  setOptions(state, options) {
    state.options = options
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}