<template>
  <v-dialog v-model="dialog.visible" width="500">
    <v-card>
      <v-card-title class="headline text-xs-center" :class="dialogHeadline.color">
        <h4>
          <v-icon :color="dialogHeadline.text" v-if="dialogHeadline.icon">{{dialogHeadline.icon}}</v-icon>
          <span class="pl-3">{{ dialog.title }}</span>
        </h4>
      </v-card-title>
      <v-card-text>
        <v-layout>
          <v-flex md12 v-html="dialog.message"></v-flex>
        </v-layout>
        <v-layout class="pt-3">
          <v-flex md12 v-if="dialog.actions" style="text-align: right">
            <v-btn
              v-for="(action, index) in dialog.actions"
              :key="index"
              dark
              flat
              :color="action.color"
              @click="disposeAction(action.action, action.args);"
            >
              <font-awesome-icon v-if="action.icon" :icon="action.icon"/>
              <span class="pl-2">{{ action.label }}</span>
            </v-btn>
            <v-btn dark flat color="grey darken-1" @click="close();">
              <font-awesome-icon icon="times"/>
              <span class="pl-2">Chiudi</span>
            </v-btn>
          </v-flex>
        </v-layout>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Dialog',
  computed: {
    ...mapGetters({
      dialog: 'dialog/dialog'
    }),
    dialogHeadline: function () {
      let headline = { icon: '', color: '' }
      if (this.dialog.type) {
        switch (this.dialog.type) {
          case 'danger':
            headline.color = 'pink darken-1 white--text'
            headline.icon = 'fas fa-times'
            headline.text = 'white'
            break
          case 'error':
            headline.color = 'pink darken-1 white--text'
            headline.icon = 'fas fa-times'
            headline.text = 'white'
            break
          case 'remove':
            headline.color = 'pink darken-1 white--text'
            headline.icon = 'fas fa-trash-alt'
            headline.text = 'white'
            break
          case 'info':
            headline.color = 'blue darken-1 white--text'
            headline.icon = 'fas fa-question'
            headline.text = 'white'
            break
          case 'edit':
            headline.color = 'light-blue darken-1 white--text'
            headline.icon = 'fas fa-pencil-alt'
            headline.text = 'white'
            break
          case 'success':
            headline.color = 'light-green darken-2 white--text'
            headline.icon = 'fas fa-plus'
            headline.text = 'white'
            break
          case 'add':
            headline.color = 'light-green darken-2 white--text'
            headline.icon = 'fas fa-plus'
            headline.text = 'white'
            break
        }
      }
      return headline
    }
  },
  methods: {
    disposeAction: function (action, args) {
      this.$store.dispatch(action, args)
      this.close()
    },
    close: function () {
      this.$store.dispatch('dialog/hide')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
